const data = JSON.stringify({
  total_pages: 5,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Typical Symptoms',
      description: 'Please indicate whether you have had the following symptoms during the past 24 hours, and how severe they were:',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Frequent urination of small amounts of urine (going to the toilet very often)",
          answers: [
            {
              id: 1,
              desc: "4 or less times per day",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "5‐6 times/ day",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "7‐8 times/day",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "9‐10 or more times/day",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Urgent urination (a sudden and uncontrollable urge to urinate)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Feeling burning pain when urinating",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Feeling incomplete bladder emptying (Still feel like you need to urinate again after urination)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Feeling pain not associated with urination in the lower abdomen (below the belly button)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "Blood seen in urine (without menses)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: 'Differential',
      description: 'Please indicate whether you have had the following symptoms during the past 24 hours, and how severe they were:',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "Flank pain (pain in one or both sides of the lower back)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "Abnormal vaginal discharge (abnormal amount, color and/or odor)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "Discharge from the urethra (urinary opening) without urination",
          answers: [
            {
              id: 1,
              desc: "",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "Feeling high body temperature/fever",
          answers: [
            {
              id: 1,
              desc: "≤99.5 °F",
              name: "None",
              points: 0
            },
            {
              id: 2,
              desc: "99.6-100.2 °F",
              name: "Yes, mild",
              points: 1
            },
            {
              id: 3,
              desc: "100.3-102.0 °F",
              name: "Yes, moderate",
              points: 2
            },
            {
              id: 4,
              desc: "≥102.1 °F",
              name: "Yes, severe",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: 'Quality of life',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "Please rate how much discomfort you have experienced because of these symptoms in the past 24 hours:",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No discomfort (No symptoms at all. I feel as good as usual)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Mild discomfort (I feel a little worse than usual)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Moderate discomfort (I feel much worse than usual)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Severe discomfort (I feel terrible)",
              points: 3
            }
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: "Please indicate how these symptoms have interfered with your everyday activities/work in the past 24 hours:",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Did not interfere at all (Working as usual on a working day)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Mildly interfered (Due to the symptoms, I work slightly less)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Moderately interfered (Daily work requires effort)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Severely interfered (I almost cannot work)",
              points: 3
            }
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "Please indicate how these symptoms have interfered with your social activities (visiting people, meeting with friends, etc) in the past 24 hours:",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Did not interfere at all (My social activities did not change in any way, I live as usual)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Mildly interfered (Insignificant decrease in activities)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Moderately interfered (Significant decrease. I have to spend more time at home)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Severely interfered (It’s terrible. I barely left the house)",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: 'Additional',
      description: 'Please indicate whether you have the following at the time of completion of this questionnaire:',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "Menstruation (Menses) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "no",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "yes",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "Premenstrual syndrome (PMS) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "no",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "yes",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "Signs of menopausal syndrome (e.g. hot flashes) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "no",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "yes",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "Pregnancy ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "no",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "yes",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "Known (diagnosed) diabetes mellitus (high sugar) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "no",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "yes",
              points: "yes"
            }
          ]
        }
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
